import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import ICAL from "ical.js"
import Calendar from "react-calendar"
import calendarIcon from "../assets/icons/calendar.svg"
import bedIcon from "../assets/icons/bed.svg"
import { Dropdown, Container, Row, Col } from "react-bootstrap"
import i18n from "../i18n"
import type value from "ical.js"

type ValuePiece = Date | null
type Value = ValuePiece | [ValuePiece, ValuePiece]

const MyCalendar = () => {
  const [events, setEvents] = useState<IEvent[]>([])
  const [date, setDate] = useState<Value>(new Date())
  const [isOpen, setIsOpen] = useState(false)
  const [active, setActive] = useState<"left" | "right" | null>(null)

  const { t } = useTranslation()

  const fakePricesRange = {
    prices: [
      {
        start_date: "2024-05-01",
        end_date: "2024-05-31",
        month: "May",
        price: 190,
      },
      {
        start_date: "2024-06-01",
        end_date: "2024-06-30",
        month: "June",
        price: 230,
      },
      {
        start_date: "2024-07-01",
        end_date: "2024-07-15",
        month: "July",
        price: 260,
      },
      {
        start_date: "2024-07-16",
        end_date: "2024-07-31",
        month: "July",
        price: 290,
      },
      {
        start_date: "2024-08-01",
        end_date: "2024-08-31",
        month: "August",
        price: 320,
      },
      {
        start_date: "2024-09-01",
        end_date: "2024-09-15",
        month: "September",
        price: 260,
      },
      {
        start_date: "2024-09-16",
        end_date: "2024-10-31",
        month: "October",
        price: 190,
      },
    ],
  }

  useEffect(() => {
    fetch("http://localhost:3001/calendar")
      .then((response) => response.text())
      .then((data) => {
        try {
          const jcalData = ICAL.parse(data)
          const comp = new ICAL.Component(jcalData)
          const vevents = comp.getAllSubcomponents("vevent")
          const parsedEvents = vevents.map((vevent: any) => {
            const event = new ICAL.Event(vevent)
            return {
              summary: event.summary,
              startDate: event.startDate.toJSDate(),
              endDate: event.endDate.toJSDate(),
            }
          })
          setEvents(parsedEvents)
        } catch (parseError) {
          console.error("Error parsing calendar data:", parseError)
        }
      })
      .catch((error) => console.error("Error fetching calendar:", error))
  }, [])

  const tileDisabled = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month") {
      return events.some((event) => date >= new Date(event.startDate) && date <= new Date(event.endDate))
    }
    return false
  }

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month") {
      // Días no disponibles
      const isUnavailable = events.some((event) => date >= new Date(event.startDate) && date <= new Date(event.endDate))

      if (isUnavailable) {
        return "unavailable-day" // Clase para días no disponibles
      }

      // Días seleccionados
      if (Array.isArray(date)) {
        const [start, end] = date
        if (date >= start && date <= end) {
          return "selected-range" // Clase para el rango seleccionado
        }
      }

      return ""
    }
  }

  const handleDropdownToggle = (isOpen: boolean) => {
    setIsOpen(isOpen)
  }

  const handleClick = (side: "left" | "right") => {
    setActive(side)
  }

  return (
    <Container className="mx-auto p-5">
      <Row className="justify-content-center">
        <img src={calendarIcon} alt="Calendar Icon" style={{ width: "20px", height: "20px" }} />
        <span className="ml-2">{t("calendar.calendar")}</span>
      </Row>

      {/* dropdown */}
      <Row>
        <Dropdown className="w-100" show={isOpen} onToggle={handleDropdownToggle}>
          <Dropdown.Toggle variant="transparent" className="w-100">
            <div className="d-flex justify-content-between align-items-center w-100">
              <span>{t("calendar.guests")}</span>
              {isOpen ? (
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.706948 6.28271L5.64645 1.75484C5.74151 1.6712 5.86818 1.62449 5.99995 1.62449C6.13172 1.62449 6.25838 1.6712 6.35345 1.75484L11.2899 6.28042L11.9969 5.63234L7.06045 1.10676C6.77452 0.856956 6.39469 0.717608 5.9997 0.717608C5.60471 0.717608 5.22487 0.856956 4.93895 1.10676L-5.14984e-05 5.63463L0.706948 6.28271Z"
                    fill="#282828"
                  />
                </svg>
              ) : (
                <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.2929 2.71729L6.35343 7.24516C6.25836 7.3288 6.1317 7.37551 5.99993 7.37551C5.86816 7.37551 5.7415 7.3288 5.64643 7.24516L0.70993 2.71958L0.00292969 3.36766L4.93943 7.89324C5.22536 8.14304 5.60519 8.28239 6.00018 8.28239C6.39517 8.28239 6.775 8.14304 7.06093 7.89324L11.9999 3.36537L11.2929 2.71729Z"
                    fill="#282828"
                  />
                </svg>
              )}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="w-100">
            <Dropdown.Item>opcion</Dropdown.Item>
            <Dropdown.Item>opcion</Dropdown.Item>
            <Dropdown.Item>opcion</Dropdown.Item>
            <Dropdown.Item>opcion</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>

      <Row className="justify-content-center my-3">
        <div className="checkinoutbox">
          <Col className={`checkin-col ${active === "left" ? "active" : ""}`} onClick={() => handleClick("left")}>
            <div>
              <p className="mb-1 ">Check in</p>
              <p className="mb-1 ">Add dates</p>
            </div>
          </Col>
          <div className="divider"></div>
          <Col className={`checkout-col ${active === "right" ? "active" : ""}`} onClick={() => handleClick("right")}>
            <div>
              <p className="mb-1 ">Check out</p>
              <p className="mb-1 ">Add dates</p>
            </div>
          </Col>
        </div>
      </Row>

      <Row className="justify-content-center d-lg-none">
        <Calendar
          className="calendarOnlyOne"
          onChange={setDate}
          value={date}
          locale={i18n.language}
          tileDisabled={tileDisabled}
          tileClassName={tileClassName}
          view="month"
          showNeighboringMonth={false}
        />
      </Row>

      <Row className="justify-content-center d-none d-lg-flex">
        <Calendar
          className="calendarLeft"
          onChange={setDate}
          value={date}
          locale={i18n.language}
          tileDisabled={tileDisabled}
          tileClassName={tileClassName}
          view="month"
          showNeighboringMonth={false}
        />

        <Calendar
          className="calendarRight"
          onChange={setDate}
          value={date}
          locale={i18n.language}
          tileDisabled={tileDisabled}
          tileClassName={tileClassName}
          view="month"
          showNeighboringMonth={false}
        />
      </Row>

      <Row className="justify-content-end mt-3">
        <button className="requestButton py-1 px-4">
          <img src={bedIcon} className="mr-2" alt="Bed Icon" style={{ width: "20px", height: "20px" }} /> {t("calendar.request")}
        </button>
      </Row>
    </Container>
  )
}

export default MyCalendar
