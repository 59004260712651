import { FormEvent, useState } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

const BackOffice = () => {
  const [name, setName] = useState("")
  const [location, setLocation] = useState("")
  const [locationMap, setLocationMap] = useState("")
  const [description, setDescription] = useState("")
  const [images, setImages] = useState("")
  const [capacity, setCapacity] = useState(0)
  const [bedrooms, setBedrooms] = useState(0)
  const [bathrooms, setBathrooms] = useState(0)
  const [price, setPrice] = useState(0) //by now is a number
  const [extras, setExtras] = useState("")

  const navigate = useNavigate()

  const createNewProperty = async (e: FormEvent) => {
    e.preventDefault()
    const propertyBody = {
      name: name,
      location: location,
      locationMap: locationMap,
      description: description,
      images: images,
      capacity: capacity,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      price: price,
      extras: extras,
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER}properties/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //  Authorization: "Bearer " + token,
        },
        body: JSON.stringify(propertyBody),
      })

      if (res.ok) {
        //  getCategories()
        //  notifyOk("Category created successfully")
        alert("property created successfully")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container className="m-0">
      <Row>
        <Col xs={1} className="bg-success ">
          <Row>
            <small>Create a property</small>
          </Row>

          <Row className="my-2">
            <small>edit a property</small>
          </Row>

          <Row className="my-2">
            <small>pending reservations</small>
          </Row>

          <Row className="my-2">
            <small>edit reservations</small>
          </Row>

          <Row className="my-2">
            <small>cancel reservations</small>
          </Row>

          <Row className="my-2">
            <small>change photos</small>
          </Row>

          <Row className="my-2">
            <small>change/add texts</small>
            {/* ojo porque ahi entonces no habria traduccion si no haces que de varios inputs y cambien o añadan alguna traduccion*/}
          </Row>

          <Row className="my-2">
            <small>change prices</small>
          </Row>
        </Col>

        <Col xs={10}>
          <ul>
            <li>selected content</li>
          </ul>
          <Form onSubmit={(e) => createNewProperty(e)}>
            <h4>Create a property</h4>

            <Form.Group>
              <Form.Control type="text" placeholder="Property name" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Control type="text" placeholder="Town location" value={location} onChange={(e) => setLocation(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Control type="text" placeholder="Map" value={locationMap} onChange={(e) => setLocationMap(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Control
                type="textarea"
                // rows={3}
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Control type="text" placeholder="Images" value={images} onChange={(e) => setImages(e.target.value)} />
            </Form.Group>

            <Form.Group>
              {/* <Form.Control type="number" placeholder="Capacity" value={capacity} onChange={(e) => setCapacity(e.target.value)} /> */}
            </Form.Group>

            <Form.Group>
              {/* <Form.Control type="number" placeholder="Bedrooms" value={bedrooms} onChange={(e) => setBedrooms(e.target.value)} /> */}
            </Form.Group>

            <Form.Group>
              {/* <Form.Control type="number" placeholder="Bathrooms" value={bathrooms} onChange={(e) => setBathrooms(e.target.value)} /> */}
            </Form.Group>

            <Form.Group>
              {/* <Form.Control type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} /> */}
            </Form.Group>

            <Form.Group>
              <Form.Control type="text" placeholder="Extras" value={extras} onChange={(e) => setExtras(e.target.value)} />
            </Form.Group>

            <div>
              <button type="submit">Submit</button>
              <button onClick={() => navigate("/home")}>Back</button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default BackOffice
