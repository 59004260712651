import { Navbar, Nav, Image } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import LanguageSwitcher from "./LanguageSwitcher"
import { useTranslation } from "react-i18next"

const MyNavbar = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const isRoot = /^\/(\/)?$|^\/\w{2}(\/)?$/.test(location.pathname)
  const textColor = isRoot ? "white" : "black"
  const theme = isRoot ? "navbarTransparent" : "navbarWhite"

  return (
    <Navbar expand="md" className={theme}>
      <Navbar.Brand href="/" className="p-0 m-0">
        <Image src="/LogoProexmeSL.png" alt="Logo" height={73} width={67} />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" className={`border-white`} />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Link to="/" className={`nav-link text-${textColor}`}>
            {t("navbar.home")}
          </Link>

          <Link to="/estate" className={`nav-link text-${textColor}`}>
            {t("navbar.estate")}
          </Link>

          <Link to="/menorca" className={`nav-link text-${textColor}`}>
            {t("navbar.menorca")}
          </Link>

          <a href="#contact" className={`nav-link text-${textColor}`}>
            {t("navbar.contactus")}
          </a>
        </Nav>

        <LanguageSwitcher />
      </Navbar.Collapse>
    </Navbar>
  )
}
export default MyNavbar
