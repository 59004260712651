import React, { useState, useRef, useEffect } from "react"
import { Container, Image } from "react-bootstrap"

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const [startIndex, setStartIndex] = useState(0)
  const [dragging, setDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [translateX, setTranslateX] = useState(0)
  const [imagesPerView, setImagesPerView] = useState(3) // Cambiar aquí
  const [isDraggable, setIsDraggable] = useState(true) // Controlar si es draggable

  const containerRef = useRef<HTMLDivElement>(null)

  const handlePrevClick = () => {
    setStartIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0))
  }

  const handleNextClick = () => {
    setStartIndex((prevIndex) => (prevIndex < images.length - imagesPerView ? prevIndex + 1 : prevIndex))
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    setDragging(true)
    setStartX(e.clientX)
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!dragging) return
    const diff = e.clientX - startX
    setTranslateX(diff)
  }

  const handleMouseUp = () => {
    setDragging(false)
    handleDragEnd()
  }

  const handleTouchStart = (e: React.TouchEvent) => {
    if (!isDraggable) return
    setDragging(true)
    setStartX(e.touches[0].clientX)
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!dragging) return
    const diff = e.touches[0].clientX - startX
    setTranslateX(diff)
  }

  const handleTouchEnd = () => {
    setDragging(false)
    handleDragEnd()
  }

  const handleDragEnd = () => {
    const imageWidth = containerRef.current ? containerRef.current.clientWidth / imagesPerView : 0
    const movedImages = Math.round(translateX / imageWidth)

    // Calcula el nuevo índice dependiendo de cuántas imágenes se arrastró
    let newIndex = startIndex - movedImages

    // Asegúrate de que el índice esté dentro de los límites
    if (newIndex < 0) {
      newIndex = 0
    } else if (newIndex > images.length - imagesPerView) {
      newIndex = images.length - imagesPerView
    }

    setStartIndex(newIndex)
    setTranslateX(0)
  }

  // Ajustar la cantidad de imágenes renderizadas según el tamaño de la pantalla
  useEffect(() => {
    const updateImagesPerView = () => {
      const width = window.innerWidth
      if (width >= 1400) {
        setImagesPerView(4)
      } else if (width < 1400 && width > 900) {
        setImagesPerView(3)
      } else if (width < 900 && width > 650) {
        setImagesPerView(2)
      } else {
        setImagesPerView(1)
      }
      setIsDraggable(width < 768)
    }
    // Permitir arrastrar solo si la pantalla es menor a 768px
    updateImagesPerView()
    window.addEventListener("resize", updateImagesPerView)

    return () => {
      window.removeEventListener("resize", updateImagesPerView)
    }
  }, [])

  return (
    <Container fluid className="image-carousel-container p-0">
      {!isDraggable && (
        <button className="carousel-button prev-button" onClick={handlePrevClick}>
          &lt;
        </button>
      )}
      <div
        className="image-container-wrapper"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className="image-container"
          style={{
            transform: `translateX(calc(-${(startIndex * 100) / imagesPerView}% + ${translateX}px))`,
            transition: dragging ? "none" : "transform 0.6s ease-in-out",
          }}
        >
          {images.map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`house-slide-${index}`}
              className="carousel-image"
              style={{ width: `calc(100% / ${imagesPerView})` }}
            />
          ))}
        </div>
      </div>
      {!isDraggable && (
        <button className="carousel-button next-button" onClick={handleNextClick}>
          &gt;
        </button>
      )}
    </Container>
  )
}

export default ImageCarousel
