import { Container, Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <div className="footer mt-5">
      <Container>
        <Row>
          <Col xs={{ span: 4, offset: 1 }} sm={{ span: 3, offset: 0 }}>
            <img src="/LogoProexmeSL.png" alt="Logo" height={130} width={120} />
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga minus, repellat repellendus consequatur sequi </p> */}
          </Col>

          <Col xs={7} sm={5} className="d-flex flex-column">
            <h5 className="mainTitle">PROEXME ACCOMMODATIONS</h5>
            <Link to="/" className="footerLink">
              {t("navbar.home")}
            </Link>

            <Link to="/estate" className="footerLink">
              {t("navbar.estate")}
            </Link>

            <Link to="/menorca" className="footerLink">
              {t("navbar.menorca")}
            </Link>

            <a href="#contact" className="footerLink">
              {t("navbar.contactus")}
            </a>
          </Col>

          <Col xs={12} sm={4} className="mt-2 mt-sm-0">
            <Row>
              <Col xs={{ span: 4, offset: 1 }} sm={{ span: 12, offset: 0 }} md={6}>
                <h5 className="mainTitle ">HELP CENTER</h5>
                <p className="footerLink">FAQs</p>
              </Col>
              <Col xs={7} sm={12} md={6}>
                <div>
                  <p className="m-0 followUs" style={{ marginBottom: "0.4375rem" }}>
                    Follow us on
                  </p>
                  <span className="mr-2">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.0256 0H9.98004C4.46977 0 0.00280762 4.46696 0.00280762 9.97723V10.0228C0.00280762 15.533 4.46977 20 9.98004 20H10.0256C15.5358 20 20.0028 15.533 20.0028 10.0228V9.97723C20.0028 4.46696 15.5358 0 10.0256 0Z"
                        fill="url(#paint0_linear_530_356)"
                      />
                      <path
                        d="M13.0975 4.06897H6.90893C5.19923 4.06897 3.80835 5.45985 3.80835 7.16955V12.8312C3.80835 14.5409 5.19923 15.9318 6.90893 15.9318H13.0975C14.8072 15.9318 16.1981 14.5409 16.1981 12.8312V7.16955C16.1981 5.45985 14.8072 4.06897 13.0975 4.06897ZM4.90214 7.16955C4.90214 6.06315 5.80253 5.16276 6.90893 5.16276H13.0975C14.2039 5.16276 15.1043 6.06315 15.1043 7.16955V12.8312C15.1043 13.9376 14.2039 14.838 13.0975 14.838H6.90893C5.80253 14.838 4.90214 13.9376 4.90214 12.8312V7.16955Z"
                        fill="white"
                      />
                      <path
                        d="M10.0032 12.8839C11.5931 12.8839 12.8873 11.5904 12.8873 9.99979C12.8873 8.40921 11.5938 7.11572 10.0032 7.11572C8.41263 7.11572 7.11914 8.40921 7.11914 9.99979C7.11914 11.5904 8.41263 12.8839 10.0032 12.8839ZM10.0032 8.21021C10.9905 8.21021 11.7935 9.01321 11.7935 10.0005C11.7935 10.9878 10.9905 11.7908 10.0032 11.7908C9.01593 11.7908 8.21293 10.9878 8.21293 10.0005C8.21293 9.01321 9.01593 8.21021 10.0032 8.21021Z"
                        fill="white"
                      />
                      <path
                        d="M13.1543 7.58161C13.5824 7.58161 13.9313 7.23337 13.9313 6.80454C13.9313 6.37571 13.5831 6.02747 13.1543 6.02747C12.7254 6.02747 12.3772 6.37571 12.3772 6.80454C12.3772 7.23337 12.7254 7.58161 13.1543 7.58161Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient id="paint0_linear_530_356" x1="2.92471" y1="17.0781" x2="17.0809" y2="2.92261" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#FAAD4F" />
                          <stop offset="0.35" stopColor="#DD2A7B" />
                          <stop offset="0.62" stopColor="#9537B0" />
                          <stop offset="1" stopColor="#515BD4" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>

                  <span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.00280762" width="20" height="20" rx="10" fill="black" />
                      <path
                        d="M12.7914 8.05365C13.6917 8.6995 14.7946 9.0795 15.9858 9.0795V6.77915C15.7604 6.77919 15.5355 6.7556 15.315 6.70871V8.51942C14.1239 8.51942 13.0211 8.13941 12.1206 7.49361V12.188C12.1206 14.5363 10.2236 16.4399 7.88377 16.4399C7.01071 16.4399 6.19924 16.175 5.52515 15.7207C6.29451 16.5102 7.36745 16.9999 8.55447 16.9999C10.8945 16.9999 12.7915 15.0964 12.7915 12.7479V8.05365H12.7914ZM13.619 5.73287C13.1589 5.22842 12.8568 4.57652 12.7914 3.85581V3.55994H12.1557C12.3157 4.47596 12.8615 5.25855 13.619 5.73287ZM7.00511 13.9187C6.74805 13.5804 6.60914 13.1666 6.60976 12.7412C6.60976 11.6671 7.47741 10.7963 8.54786 10.7963C8.74736 10.7962 8.94566 10.8269 9.13577 10.8874V8.53566C8.9136 8.5051 8.68936 8.49212 8.46522 8.49688V10.3274C8.27496 10.2668 8.07657 10.2361 7.87703 10.2363C6.80658 10.2363 5.93897 11.107 5.93897 12.1812C5.93897 12.9408 6.37265 13.5983 7.00511 13.9187Z"
                        fill="#FF004F"
                      />
                      <path
                        d="M12.1206 7.49356C13.0211 8.13936 14.1239 8.51937 15.315 8.51937V6.70866C14.6501 6.56654 14.0615 6.21786 13.619 5.73287C12.8615 5.2585 12.3158 4.47591 12.1557 3.55994H10.4859V12.7478C10.4821 13.8189 9.61594 14.6862 8.54779 14.6862C7.91835 14.6862 7.35915 14.3851 7.005 13.9187C6.37258 13.5983 5.9389 12.9407 5.9389 12.1813C5.9389 11.1072 6.80651 10.2363 7.87696 10.2363C8.08205 10.2363 8.27973 10.2684 8.46515 10.3274V8.49693C6.16639 8.54459 4.31763 10.4296 4.31763 12.7479C4.31763 13.9051 4.77801 14.9542 5.52522 15.7208C6.19931 16.175 7.01079 16.4399 7.88385 16.4399C10.2238 16.4399 12.1207 14.5363 12.1207 12.188V7.49356H12.1206Z"
                        fill="white"
                      />
                      <path
                        d="M15.315 6.70871V6.21911C14.7155 6.22003 14.1277 6.05153 13.619 5.73288C14.0693 6.22762 14.6622 6.56875 15.315 6.70871ZM12.1558 3.55999C12.1405 3.47245 12.1288 3.38433 12.1206 3.29587V3H9.81502V12.188C9.81134 13.2589 8.94522 14.1262 7.87697 14.1262C7.56335 14.1262 7.26724 14.0515 7.00501 13.9188C7.35916 14.3852 7.91836 14.6862 8.54781 14.6862C9.61586 14.6862 10.4822 13.819 10.4859 12.7479V3.55999H12.1558ZM8.46526 8.49698V7.97577C8.27261 7.94934 8.07838 7.93608 7.88391 7.93618C5.5438 7.93613 3.64685 9.83981 3.64685 12.188C3.64685 13.6601 4.39239 14.9576 5.52528 15.7207C4.77807 14.9542 4.31769 13.9051 4.31769 12.7479C4.31769 10.4296 6.1664 8.54465 8.46526 8.49698Z"
                        fill="#00F2EA"
                      />
                    </svg>
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="footerDivider" />
      <p className="allrights text-center">© 2024 Proexme Accommodations. All rights reserved</p>
    </div>
  )
}

export default Footer
