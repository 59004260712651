// import { Button, Container } from "react-bootstrap"
// import { Link } from "react-router-dom"
const Suggestions = () => {
  return (
    <>
      <h1>Menorca</h1>

      <ul>
        <li>beaches</li>
        <li>restaurants? or generic link</li>
        <li>places to visit in menorca</li>
        <li>interest links , "Consell", "web to see beach status", "la Mola", "...."</li>
      </ul>
      <a target="_blank" rel="noopener noreferrer" href="https://www.platgesdebalears.com/provincia.php/provincia/menorca?lang=es&n=menorca">
        Beach status
      </a>
    </>
  )
}
export default Suggestions
