import React, { useEffect, useState, type FC } from "react"
import { Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const GuestBook: React.FC = () => {
  const { t } = useTranslation()
  const [reviews, setReviews] = useState<Review[]>([])
  const [startIndex, setStartIndex] = useState(0)
  const reviewsPerView = 4

  const getReviews = () => {
    // fake reviews
    const fetchedReviews: Review[] = [
      { stars: 4.5, comment: "Great place!", author: "John Doe", date: "September 12, 2023" },
      { stars: 5, comment: "Amazing experience!", author: "Jane Smith", date: "October 5, 2023" },
      { stars: 2, comment: "meh!", author: "John Smith", date: "October 5, 2023" },
      {
        stars: 1.5,
        comment:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit necessitatibus magnam quod eveniet nulla, commodi error quis eligendi placeat nostrum ipsa eum reiciendis vero quaerat tempora aliquid in accusamus blanditiis.",
        author: "Pablo Smith",
        date: "October 5, 2023",
      },
      { stars: 1.5, comment: "oh la la experience!", author: "Pablo Test Smith", date: "October 5, 2023" },
      { stars: 1.5, comment: "oh la la experience!", author: "Pablo Test Smith", date: "October 5, 2023" },
      { stars: 1.5, comment: "oh la la experience!", author: "Pablo Test Smith", date: "October 5, 2023" },
      { stars: 1.5, comment: "oh la la experience!", author: "Pablo Test Smith", date: "October 5, 2023" },
    ]
    setReviews(fetchedReviews)
  }

  useEffect(() => {
    getReviews()
  }, [])

  const handlePrevClick = () => {
    setStartIndex(startIndex > 0 ? startIndex - 1 : 0)
  }

  const handleNextClick = () => {
    setStartIndex(startIndex < reviews.length - reviewsPerView ? startIndex + 1 : startIndex)
  }

  const renderStars = (stars: number) => {
    const fullStars = Math.floor(stars)
    const halfStar = stars % 1 !== 0
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0)

    return (
      <div className="stars">
        {Array(fullStars)
          .fill("★")
          .map((star, index) => (
            <span key={index} className="full-star">
              {star}
            </span>
          ))}
        {halfStar && <span className="half-star">★</span>}
        {Array(emptyStars)
          .fill("★")
          .map((star, index) => (
            <span key={index + fullStars + 1} className="empty-star">
              {star}
            </span>
          ))}
      </div>
    )
  }

  return (
    <Container fluid className="guestContainer">
      <div className="d-flex align-items-center" style={{ marginBottom: "1.125rem" }}>
        <div className="line"></div>
        <h3 className="ml-2 titles">{t("guestbook.title")}</h3>
      </div>

      <p className="m-0">{t("guestbook.description")}</p>

      <div className="carousel position-relative">
        <button className="carousel-button prev-button" onClick={handlePrevClick}>
          &lt;
        </button>
        <div className="carousel-container d-flex overflow-hidden">
          {reviews.slice(startIndex, startIndex + reviewsPerView).map((review, index) => (
            <div key={index} className="review">
              <div>{renderStars(review.stars)}</div>
              <p className="reviewComment">"{review.comment}"</p>
              <div className="mt-auto">
                <p className="reviewAuthor mb-1">{review.author}</p>
                <p className="reviewDate">{review.date}</p>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-button next-button" onClick={handleNextClick}>
          &gt;
        </button>
      </div>
    </Container>
  )
}

export default GuestBook
